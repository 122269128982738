import language from "../languages";
import homeIcon from "../assets/img/menu/beranda.png";
import financeIcon from "../assets/img/menu/penganggaran.png";

export const SIDEBAR__DASHBOARD_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: "/dashboard",
  },
  {
    title: "Tagihan Siswa",
    className: "menu__left-icon--small",
    path: "/dashboard/tagihan-siswa",
    permission: "tagihan_siswa",
    iconImage: financeIcon,
    menu: [
      {
        title: "Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 40,
        path: "/dashboard/tagihan-siswa/pembayaran",
        permission: "pembayaran_siswa",
      },
      {
        title: "Riwayat Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 40,
        path: "/dashboard/tagihan-siswa/riwayat",
        permission: "riwayat_pembayaran_siswa",
      },
      {
        title: "Rincian Pembayaran",
        icon: "icon-angle-right",
        className: "menu__left-icon--small",
        padding: 40,
        path: "/dashboard/tagihan-siswa/rincian-pembayaran",
        permission: "rincian_pembayaran_siswa",
      },
    ],
  },
  // {
  //   title: language.translate.SIDEBAR__SETTING,
  //   iconImage: settingIcon,
  //   menu: [
  //     {
  //       title: "Ganti Kata Sandi",
  //       path: "/dashboard/pengaturan/ganti-kata-sandi",
  //     },
  //   ],
  // },
];

export const SIDEBAR_ADMISSION_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: "/admission",
  },
];
